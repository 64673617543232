/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as LegalImport } from './routes/_legal'

// Create Virtual Routes

const IndexLazyImport = createFileRoute('/')()
const LegalUeberLazyImport = createFileRoute('/_legal/ueber')()
const LegalKontaktLazyImport = createFileRoute('/_legal/kontakt')()
const LegalImpressumLazyImport = createFileRoute('/_legal/impressum')()
const LegalDatenschutzLazyImport = createFileRoute('/_legal/datenschutz')()

// Create/Update Routes

const LegalRoute = LegalImport.update({
  id: '/_legal',
  getParentRoute: () => rootRoute,
} as any)

const IndexLazyRoute = IndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/index.lazy').then((d) => d.Route))

const LegalUeberLazyRoute = LegalUeberLazyImport.update({
  id: '/ueber',
  path: '/ueber',
  getParentRoute: () => LegalRoute,
} as any).lazy(() => import('./routes/_legal/ueber.lazy').then((d) => d.Route))

const LegalKontaktLazyRoute = LegalKontaktLazyImport.update({
  id: '/kontakt',
  path: '/kontakt',
  getParentRoute: () => LegalRoute,
} as any).lazy(() =>
  import('./routes/_legal/kontakt.lazy').then((d) => d.Route),
)

const LegalImpressumLazyRoute = LegalImpressumLazyImport.update({
  id: '/impressum',
  path: '/impressum',
  getParentRoute: () => LegalRoute,
} as any).lazy(() =>
  import('./routes/_legal/impressum.lazy').then((d) => d.Route),
)

const LegalDatenschutzLazyRoute = LegalDatenschutzLazyImport.update({
  id: '/datenschutz',
  path: '/datenschutz',
  getParentRoute: () => LegalRoute,
} as any).lazy(() =>
  import('./routes/_legal/datenschutz.lazy').then((d) => d.Route),
)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/_legal': {
      id: '/_legal'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof LegalImport
      parentRoute: typeof rootRoute
    }
    '/_legal/datenschutz': {
      id: '/_legal/datenschutz'
      path: '/datenschutz'
      fullPath: '/datenschutz'
      preLoaderRoute: typeof LegalDatenschutzLazyImport
      parentRoute: typeof LegalImport
    }
    '/_legal/impressum': {
      id: '/_legal/impressum'
      path: '/impressum'
      fullPath: '/impressum'
      preLoaderRoute: typeof LegalImpressumLazyImport
      parentRoute: typeof LegalImport
    }
    '/_legal/kontakt': {
      id: '/_legal/kontakt'
      path: '/kontakt'
      fullPath: '/kontakt'
      preLoaderRoute: typeof LegalKontaktLazyImport
      parentRoute: typeof LegalImport
    }
    '/_legal/ueber': {
      id: '/_legal/ueber'
      path: '/ueber'
      fullPath: '/ueber'
      preLoaderRoute: typeof LegalUeberLazyImport
      parentRoute: typeof LegalImport
    }
  }
}

// Create and export the route tree

interface LegalRouteChildren {
  LegalDatenschutzLazyRoute: typeof LegalDatenschutzLazyRoute
  LegalImpressumLazyRoute: typeof LegalImpressumLazyRoute
  LegalKontaktLazyRoute: typeof LegalKontaktLazyRoute
  LegalUeberLazyRoute: typeof LegalUeberLazyRoute
}

const LegalRouteChildren: LegalRouteChildren = {
  LegalDatenschutzLazyRoute: LegalDatenschutzLazyRoute,
  LegalImpressumLazyRoute: LegalImpressumLazyRoute,
  LegalKontaktLazyRoute: LegalKontaktLazyRoute,
  LegalUeberLazyRoute: LegalUeberLazyRoute,
}

const LegalRouteWithChildren = LegalRoute._addFileChildren(LegalRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexLazyRoute
  '': typeof LegalRouteWithChildren
  '/datenschutz': typeof LegalDatenschutzLazyRoute
  '/impressum': typeof LegalImpressumLazyRoute
  '/kontakt': typeof LegalKontaktLazyRoute
  '/ueber': typeof LegalUeberLazyRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexLazyRoute
  '': typeof LegalRouteWithChildren
  '/datenschutz': typeof LegalDatenschutzLazyRoute
  '/impressum': typeof LegalImpressumLazyRoute
  '/kontakt': typeof LegalKontaktLazyRoute
  '/ueber': typeof LegalUeberLazyRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexLazyRoute
  '/_legal': typeof LegalRouteWithChildren
  '/_legal/datenschutz': typeof LegalDatenschutzLazyRoute
  '/_legal/impressum': typeof LegalImpressumLazyRoute
  '/_legal/kontakt': typeof LegalKontaktLazyRoute
  '/_legal/ueber': typeof LegalUeberLazyRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths: '/' | '' | '/datenschutz' | '/impressum' | '/kontakt' | '/ueber'
  fileRoutesByTo: FileRoutesByTo
  to: '/' | '' | '/datenschutz' | '/impressum' | '/kontakt' | '/ueber'
  id:
    | '__root__'
    | '/'
    | '/_legal'
    | '/_legal/datenschutz'
    | '/_legal/impressum'
    | '/_legal/kontakt'
    | '/_legal/ueber'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexLazyRoute: typeof IndexLazyRoute
  LegalRoute: typeof LegalRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  IndexLazyRoute: IndexLazyRoute,
  LegalRoute: LegalRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_legal"
      ]
    },
    "/": {
      "filePath": "index.lazy.tsx"
    },
    "/_legal": {
      "filePath": "_legal.tsx",
      "children": [
        "/_legal/datenschutz",
        "/_legal/impressum",
        "/_legal/kontakt",
        "/_legal/ueber"
      ]
    },
    "/_legal/datenschutz": {
      "filePath": "_legal/datenschutz.lazy.tsx",
      "parent": "/_legal"
    },
    "/_legal/impressum": {
      "filePath": "_legal/impressum.lazy.tsx",
      "parent": "/_legal"
    },
    "/_legal/kontakt": {
      "filePath": "_legal/kontakt.lazy.tsx",
      "parent": "/_legal"
    },
    "/_legal/ueber": {
      "filePath": "_legal/ueber.lazy.tsx",
      "parent": "/_legal"
    }
  }
}
ROUTE_MANIFEST_END */
